/* @flow */

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Carousel from 'rsuite/Carousel';
import Button from 'rsuite/Button';

import { getCurrencyTypeDescription } from '../utils/currencyTypes';

import {
  addProductToCart,
} from '../actions/account';

import {
  toggleProductFromFavorite,
} from '../actions/products';

import './Search.css';

function ProductPreview(props:Object):Object {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.account.cart);
  const currentUser = useSelector(state => state.account.user) || {};
  const products = useSelector(state => state.products);
  const navigate = useNavigate();

  const product = props.product;
  const hasOffer = product.data.offers && Object.keys(product.data.offers).length > 0;
  let showOfferBadge = hasOffer; 

  const calculateBestOffer = (product:Object) => {
    const offers = product.data.offers;
    const cost = parseFloat(product.data.cost);
    let bestOffer = null;
    let lowestPrice = cost;

    if (offers) {
      Object.keys(offers).forEach((key) => {
        const offer = offers[key];
        const offerValue = parseFloat(offer.offerValue);
        let finalPrice = cost;

        const now = new Date();
        const startDate = new Date(offer.startDate.seconds * 1000);
        const endDate = new Date(offer.endDate.seconds * 1000);

        if (now >= startDate && now <= endDate) {
          const isVIP = currentUser.vip; 

          if (offer.applicability === 'vip' && !isVIP) {
            return;
          }

          if (offer.offerType === 'amount') {
            finalPrice = cost - offerValue;
          } else if (offer.offerType === 'percentage') {
            finalPrice = cost - (cost * (offerValue / 100));
          } else if (offer.offerType === 'productQuantity') {
            finalPrice = cost; 
          }

          if (finalPrice <= lowestPrice) {
            lowestPrice = finalPrice;
            bestOffer = offer;
          }
        }
      });
    }

    return { bestOffer, lowestPrice };
  };

  const addProductClick = (product, variant, e) => {
    e.stopPropagation();
    if (variant) {
      dispatch(addProductToCart(product.id, variant, 1));
    } else {
      dispatch(addProductToCart(product.id, null, 1));
    }
  };

  const toggleFavoriteClick = (product, e) => {
    e.stopPropagation();
    const isFavorite = products.favorites.includes(product.id);
    dispatch(toggleProductFromFavorite(product, isFavorite));
  };

  const isProductInCart = (productId) => {
    return !!cart[productId];
  };

  const { bestOffer, lowestPrice } = calculateBestOffer(product);

  const onClickProduct = (productId) => {
    navigate(`/product/${productId}`);
  };

  if (hasOffer) {
    showOfferBadge = Object.values(product.data.offers).some(offer => {
      return offer.applicability !== 'vip' || currentUser.vip;
    });
  }

  return (
    product.data.variables && product.data.variables?.length > 0 ? (
      product.data.variables.map((variant, index) =>
      {  
        const variantCost = parseFloat(variant.cost);
        const variantBestOffer = calculateBestOffer({ ...product, data: { ...product.data, cost: variantCost } }).bestOffer;
        const variantLowestPrice = calculateBestOffer({ ...product, data: { ...product.data, cost: variantCost } }).lowestPrice;
      <div
      key={product.id}
      className="mb-item"
      onClick={() => onClickProduct(product.id)}
      style={{ cursor: 'pointer' }}
    >
      <div className="mb-item-image">
        <Carousel
          className="previews-slider"
          shape="bar"
          autoplay
        >
          {product.data.images.length > 0 ? (
            product.data.images.map((imgId) => (
              <img
                key={imgId}
                src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fproducts%2Fthumb%2F${imgId}?alt=media`}
                alt={product.data.name}
                className="mb-item-image-element"
              />
            ))
          ) : (
            <img
              src="https://img.freepik.com/vector-premium/no-hay-foto-disponible-icono-vector-simbolo-imagen-predeterminado-imagen-proximamente-sitio-web-o-aplicacion-movil_87543-10639.jpg"
              alt={product.data.name}
              className="mb-item-image-element"
            />
          )}
          
        </Carousel>
        {showOfferBadge && (
          <div className="offer-badge">Oferta</div>
        )}
        <button
          className={`heart-button ${products.favorites.includes(product.id) ? 'filled' : ''}`}
          onClick={(e) => toggleFavoriteClick(product, e)}
        >
          <i className="fas fa-heart"></i>
        </button>
      </div>
      <div className="mb-item-title">{product.data.name}</div>
      <div className="mb-item-title">{variant.name}</div>
      <div className="mb-item-price">
        {hasOffer && bestOffer ? (
          <>
            <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '10px' }}>
              {`Antes: ${variant.cost} ${getCurrencyTypeDescription(product.data.currency)}`}
            </span>
            {bestOffer.offerType !== 'productQuantity' ? (
              <span>
                {`Ahora: ${variantLowestPrice.toFixed(2)} ${getCurrencyTypeDescription(product.data.currency)}`}
              </span>
            ) : (
              <span>
                {`${variantBestOffer.offerValue} % menos en el ${variantBestOffer.productQuantity} productos`}
              </span>
            )}
          </>
        ) : (
          <>
            {product.data.showCost && variant.cost != null && (
              <span>
                {`Precio: ${variant.cost} ${
                  getCurrencyTypeDescription(product.data.currency)
                }`}
              </span>
            )}
          </>
        )}
      </div>
      <div className="mb-item-button">
        {isProductInCart(product.id) ? (
          <span> <i className="fas fa-shopping-cart"></i> En el carrito</span>
        ) : (
          <Button onClick={(e) => addProductClick(product, variant, e)}>
            <i className="fas fa-shopping-cart"></i> Añadir al carrito
          </Button>
        )}
      </div>
    </div>
    })
    ):(
      <div
      key={product.id}
      className="mb-item"
      onClick={() => onClickProduct(product.id)}
      style={{ cursor: 'pointer' }}
    >
      <div className="mb-item-image">
        <Carousel
          className="previews-slider"
          shape="bar"
          autoplay
        >
          {product.data.images.length > 0 ? (
            product.data.images.map((imgId) => (
              <img
                key={imgId}
                src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fproducts%2Fthumb%2F${imgId}?alt=media`}
                alt={product.data.name}
                className="mb-item-image-element"
              />
            ))
          ) : (
            <img
              src="https://img.freepik.com/vector-premium/no-hay-foto-disponible-icono-vector-simbolo-imagen-predeterminado-imagen-proximamente-sitio-web-o-aplicacion-movil_87543-10639.jpg"
              alt={product.data.name}
              className="mb-item-image-element"
            />
          )}
          
        </Carousel>
        {showOfferBadge && (
          <div className="offer-badge">Oferta</div>
        )}
        <button
          className={`heart-button ${products.favorites.includes(product.id) ? 'filled' : ''}`}
          onClick={(e) => toggleFavoriteClick(product, e)}
        >
          <i className="fas fa-heart"></i>
        </button>
      </div>
      <div className="mb-item-title">{product.data.name}</div>
      <div className="mb-item-price">
        {hasOffer && bestOffer ? (
          <>
            <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '10px' }}>
              {`Antes: ${product.data.cost} ${getCurrencyTypeDescription(product.data.currency)}`}
            </span>
            {bestOffer.offerType !== 'productQuantity' ? (
              <span>
                {`Ahora: ${lowestPrice.toFixed(2)} ${getCurrencyTypeDescription(product.data.currency)}`}
              </span>
            ) : (
              <span>
                {`${bestOffer.offerValue} % menos en el ${bestOffer.productQuantity} productos`}
              </span>
            )}
          </>
        ) : (
          <>
            {product.data.showCost && product.data.cost != null && (
              <span>
                {`Precio: ${product.data.cost} ${
                  getCurrencyTypeDescription(product.data.currency)
                }`}
              </span>
            )}
          </>
        )}
      </div>
      <div className="mb-item-button">
        {isProductInCart(product.id) ? (
          <span> <i className="fas fa-shopping-cart"></i> En el carrito</span>
        ) : (
          <Button onClick={(e) => addProductClick(product, null, e)}>
            <i className="fas fa-shopping-cart"></i> Añadir al carrito
          </Button>
        )}
      </div>
    </div>
    )
  );
}

export default ProductPreview;
