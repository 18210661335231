/* @flow */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import CustomProvider from 'rsuite/CustomProvider';

import './index.css';

import './localized';

import App from './App';
// import * as serviceWorker from './serviceWorker';

import { configureStore } from './store';

import {
  HashRouter,
} from 'react-router-dom';

window.configClientKey = 'nea'; // 'nea' \ 'test'
// if (process.env.NODE_ENV === 'development') {
//   window.configClientKey = 'test';
// }

const store = configureStore();
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CustomProvider>
      <Provider store={store}>
        <HashRouter>
          <App />
        </HashRouter>
      </Provider>
    </CustomProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
