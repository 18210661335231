/* @flow */

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'rsuite/Input';
import Button from 'rsuite/Button';
import Avatar from 'rsuite/Avatar';
import Popover from 'rsuite/Popover';
import Whisper from 'rsuite/Whisper';
import InputNumber from 'rsuite/esm/InputNumber';

import OfferProduct from './OfferProduct';

import { getPaymentTypeDescription } from '../utils/paymentTypes';
import { getDeliveryTypeDescription } from '../utils/deliveryTypes';
import { getCurrencyTypeDescription } from '../utils/currencyTypes';

import {
  getProduct,
  getProductsComments,
  addProductComment,
  toggleProductFromFavorite,
  deleteProductComment,
  getTypesList,
  getBrandsList,
  getProductsRecommendations,
} from '../actions/products';
import {
  addProductToCart,
} from '../actions/account';

import ProductPreview from './ProductPreview';

import './Product.css';

function Product(props:Object) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [typesList, setTypesList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [productRecommendations, setProductRecommendations] = useState([]);
  const cart = useSelector(state => state.account.cart);
  const products = useSelector(state => state.products);
  const product = products.list.find(product => product.id === productId);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [commentText, setCommentText] = useState('');
  const [rating, setRating] = useState(null); 
  const [quantity, setQuantity] = useState(1);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const userState = useSelector(state => state.account.user);
  const currentUser = userState || {};
  const branches = useSelector(state => state.branches.list); 

  const productData = useSelector(state => (
    state.products.currProduct?.id === productId &&
    state.products.currProduct
  )) || product?.data;

  const [selectedVariant, setSelectedVariant] = useState(
    (productData?.variables && productData.variables.length > 0) ?
      productData.variables[0] : null);

  const currentUserAdmin = currentUser && currentUser.admin;
  const currentUserVip = currentUser && currentUser.vip;
  const comments = useSelector(state => state.products.comments[productId]) || [];

  // Función para obtener el nombre del branch dado su ID
  const getBranchName = (branchId, branches) => {
    const branch = branches.find(item => item.id === branchId);
    return branch ? branch.data.name : "Nombre desconocido";
  };

  const isFavorite = products.favorites.includes(productId);
  const isInCart = () => {
    if (selectedVariant) {
      return cart[productId] && cart[productId][selectedVariant.name] ? true : false;
    } else {
      return cart[productId] ? true : false;
    }
  };
  

  useEffect(() => {
    function loadComments() {
      dispatch(getProductsComments(productId));
    }
    if (productData) {
      loadComments();
    } else {
      dispatch(getProduct(productId)).then((data) => {
        if (data?.variables && data.variables.length > 0) {
          setSelectedVariant(data.variables[0]);
        }
        dispatch(getProductsRecommendations({
          tags: data.tags,
          type: data.type,
        })).then((res) => {
          setProductRecommendations(res);
        });
        loadComments();
      });
    }
    dispatch(getTypesList()).then((res) => {
      setTypesList(res);
    });
    dispatch(getBrandsList()).then((res) => {
      setBrandsList(res);
    });
  }, []);

  const addProductClick = () => {
      dispatch(addProductToCart(productId, selectedVariant,  quantity)); 
  };

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleOfferProduct = () => {
    setShowOfferModal(true); 
  };

  const handleCloseOfferModal = () => {
    setShowOfferModal(false); 
  };

  const handleVariantClick = (variant) => {
    setSelectedVariant(variant);
    setQuantity(variant.minAmount);
  };

  const addCurrentComment = () => {
    if (commentText.trim() || rating > 0) { 
      dispatch(addProductComment(productId, commentText, rating));
      setCommentText('');
      setRating(null); 
    }
  };

  const onTagClick = (tag:string) => {
    navigate(`/search/?tag=${tag}`);
  };

  const toggleFavoriteClick = () => {
    dispatch(toggleProductFromFavorite(product, isFavorite));
  };

  const getApplicableOffers = (product) => {
    const offers = productData.offers;
    const cost = parseFloat(productData.cost);
    let applicableOffers = [];

    if (offers) {
      Object.keys(offers).forEach((key) => {
        const offer = offers[key];
        const offerValue = parseFloat(offer.offerValue);
        let finalPrice = cost;

        const now = new Date();
        const startDate = new Date(offer.startDate.seconds * 1000);
        const endDate = new Date(offer.endDate.seconds * 1000);

        if (now >= startDate && now <= endDate) {
          const isVIP = currentUser.vip;

          if (offer.applicability === 'vip' && !isVIP) {
            return;
          }

          if (offer.offerType === 'amount') {
            finalPrice = cost - offerValue;
          } else if (offer.offerType === 'percentage') {
            finalPrice = cost - (cost * (offerValue / 100));
          } else if (offer.offerType === 'productQuantity') {
            finalPrice = cost;
          }

          if (finalPrice <= cost) {
            applicableOffers.push(offer);
          }
        }
      });
    }

    return applicableOffers;
  };

  if (!productData) {
    return null;
  }

  const handleDeleteComment = (commentId) => {
    dispatch(deleteProductComment(productId, commentId));
  };

  const openEdit =() => {
    navigate(`/edit-product/${productId}`);
  };

  const StarRating = ({ rating, setRating }) => {
    return (
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((value) => (
          <i
            key={value}
            className={`fa-star ${value <= rating ? 'fas' : 'far'}`}
            onClick={() => setRating(value)}
            style={{ cursor: 'pointer', color: value <= rating ? '#ffc107' : '#e4e5e9', margin: '0.5%', fontSize:'20px'}}
          />
        ))}
      </div>
    );
  };

  const applicableOffers = getApplicableOffers(productData);
  return (
    <div className="mb-product">
      {currentUserAdmin ? (
        <Button
          className="btn-edit"
          appearance="primary"
          block
          onClick={openEdit}
        >
          {window.lc('Edit')}
        </Button>
      ) : null}
      <div className="mb-product-details">
        <div className="mb-product-images">
          {/* Miniaturas */}
          <div className="thumbnail-container">
            {productData?.images.length > 0 && (
              productData.images.map((imgId, index) => (
                <img
                  key={imgId}
                  src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fproducts%2Fthumb%2F${imgId}?alt=media`}
                  alt={`${productData.name} - Thumbnail ${index + 1}`}
                  className={`thumbnail ${index === selectedImageIndex ? 'selected' : ''}`}
                  onClick={() => handleThumbnailClick(index)}
                />
              ))
            )}
          </div>

          {/* Imagen principal */}
          <div className="main-image-container">
          {productData.images.length > 0 ? (
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/${window.configClientKey}%2Fproducts%2Foriginal%2F${productData.images[selectedImageIndex]}?alt=media`}
              alt={`${productData.name} - Main Image`}
              className="main-image"
            />
          ) : (
            <img
              src="https://img.freepik.com/vector-premium/no-hay-foto-disponible-icono-vector-simbolo-imagen-predeterminado-imagen-proximamente-sitio-web-o-aplicacion-movil_87543-10639.jpg"
              alt={productData.name}
              className="main-image"
            />
          )}
            
          </div>
        </div>

        {/* Información del producto */}
        <div className="mb-product-info">
          <div className="product-header">
            <h1>{productData.name}</h1>
            {currentUser.uid ? (
              <Button
                className={`fav-button ${isFavorite ? 'filled' : ''}`}
                onClick={toggleFavoriteClick}
              >
                <i className="fas fa-heart"></i>
                {isFavorite ? ' Quitar de favoritos' : ' Añadir a favoritos'}
              </Button>
            ) : null}
          </div>
          
          <div
            className="desc"
            dangerouslySetInnerHTML={{ __html: productData.description }}
          ></div>
         <span>
          <strong>Unidades totales disponibles: </strong>
          {productData.storage && productData.storage > 0 
            ? productData.storage 
            : "0"}
        </span>
        {productData.branches &&
          Object.entries(productData.branches).map(([key, branch]) => (
            branch.general > 0 && (
              <div key={key}>
                <strong>Unidades disponibles en {getBranchName(key, branches)}:</strong>{" "}
                {branch.general > 0 ? branch.general : 0}
              </div>
            )
          ))
        }


          <span>
            <strong>Entregas Disponibles:</strong>
            {productData.delivery && productData.pickup
              ? "Delivery y en tienda"
              : productData.delivery
              ? "Delivery"
              : productData.pickup
              ? "En tienda"
              : "No disponible"}
          </span>
          <span>
          <strong>Pagos Disponibles:</strong>
          {productData.paymentTypes &&
          Object.keys(productData.paymentTypes).map((paymentType, index) => {
            if (paymentType === 'points' && !currentUserVip) {
              return null;
            }
            if (productData.paymentTypes[paymentType]) {
              return (
                <span key={index}>
                  {paymentType.charAt(0).toUpperCase() + paymentType.slice(1)}
                  {index < Object.keys(productData.paymentTypes).length - 1 ? ', ' : ''}
                </span>
              );
            }
            return null;
          })}
        </span>
          <div className="tags-container">
            {productData.brand != null && productData.brand != 'None' ? (
              <span  className="tag">
                  {brandsList.find(item => item.value === productData.brand)?.label}
              </span>
            ) : null}
          <span  className="tag">
            {typesList.find(item => item.value === productData.type)?.label}
          </span>
          {productData.tags.map((tag, index) => (
            <span
              key={index}
              className="tag tag-clickable"
              onClick={onTagClick.bind(null, tag)}
            >
              {tag}
            </span>
          ))}
          </div>
          {/* Mostrar todas las ofertas aplicables */}
          {applicableOffers.length > 0 && (
            <div className="offers-section">
              <h3>Ofertas disponibles:</h3>
              {applicableOffers.map((offer, index) => (
                <div key={index} className="offer">
                  <span>
                    {offer.offerType === 'amount' && (
                      <>
                        <span>{offer.offerValue} {getCurrencyTypeDescription(productData.currency)} de descuento</span>
                        <span className="offer-description">{offer.description}</span>
                      </>
                    )}
                    {offer.offerType === 'percentage' && (
                      <>
                        <span>{offer.offerValue}% de descuento</span>
                        <span className="offer-description">{offer.description}</span>
                      </>
                    )}
                    {offer.offerType === 'productQuantity' && (
                      <>
                        <span>{offer.offerValue}% de descuento al comprar {offer.productQuantity} o más</span>
                        <span className="offer-description">{offer.description}</span>
                      </>
                    )}
                  </span>
                </div>
              ))}
            </div>
          )}
          {productData.variables?.length > 0 && (
            <div className="offers-section">
              <h3>Variantes del producto</h3>
              {productData.variables.map((variable) => (
                <Button
                  key={variable.name}
                  appearance = {selectedVariant?.name === variable?.name ? 'primary' : 'ghost'}
                  color={selectedVariant?.name === variable?.name ? 'red' : 'blue'}
                  size={selectedVariant?.name === variable?.name ? 'md' : 'sm'}
                  onClick={() => handleVariantClick(variable)}
                >
                  {variable.name}
                </Button>
              ))}
            </div>
          )}
          {productData.paymentTypes?.points &&  currentUserVip && (
            <div className="quantity-cart-container">
              <span className='precioSpan' style={{color:'white'}}>
                {selectedVariant ? selectedVariant.points : productData.points} Nea Puntos
              </span>
            </div>
          )}
          {productData.showCost &&
          (
            selectedVariant ? selectedVariant.cost : productData.cost
          ) != null && (
            <div className="quantity-cart-container">
              <span className='precioSpan' style={{color:'white'}}>
                {selectedVariant ? selectedVariant.cost : productData.cost} {getCurrencyTypeDescription(productData.currency)}
              </span>
            </div>
          )}
          {currentUser.uid ? (
            <div className="quantity-cart-container">
              {isInCart() ? (
                <span> <i className="fas fa-shopping-cart"></i> En el carrito</span>
              ) : (
                <>
                  {productData.variables?.length > 0 ? selectedVariant?.name : null}
                  <InputNumber
                    value={quantity}
                    min={selectedVariant ? selectedVariant.minAmount : (productData.minAmount ? productData.minAmount : 1)}
                    max={selectedVariant ? selectedVariant.maxAmount : (productData.maxAmount ? productData.maxAmount : 5)}
                    onChange={(value) => setQuantity(value)}
                    style={{width:'80px', marginRight:'1%'}}
                  >
                  </InputNumber >
                  <Button
                    onClick={addProductClick}
                    disabled={isInCart()}
                  >
                    <i className="fas fa-shopping-cart"></i> Añadir al carrito
                  </Button>
                </>
              )}
            </div>
          ) : null}

          {/* Botón de ofertar solo para administradores */}
          {currentUser.admin && (
            <div className="quantity-cart-container">
              <Button
                onClick={handleOfferProduct}
                disabled={productData.isInOffer} 
              >
                <i className="fa-regular fa-badge-percent"></i> Ofertar Producto
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Similar Products */}
      <div className="mb-product-recommendations">
        {productRecommendations.length > 0 ?
          productRecommendations.map((product) => (
            <ProductPreview
              key={product.id}
              product={product}
            />
          )) : null
        }
      </div>

      {/* Sección de comentarios */}
      <div className="mb-comments-section">
        <h2>Opiniones de clientes</h2>
        {currentUser.uid ? (
          <div className="comments-input">
            <div className="rating-input">
              <Input
                value={commentText}
                placeholder="Añade tu comentario"
                onChange={setCommentText}
              />
              <Button
                className="save-btn"
                onClick={addCurrentComment}
              >
                Opinar
              </Button>
            </div>
            <StarRating rating={rating} setRating={setRating} />
          </div>
        ) : null}
        <div className="mb-comments">
          {comments && comments.map((comment) => {
            if (!comment.data?.userId) return null;

            const isCurrentUserComment = comment.data.userId === currentUser.uid;
            const showOptions = currentUser.admin || isCurrentUserComment;

            return (
              <div className="comment" key={comment.id}>
                <div className="comment-avatar">
                  <Avatar
                    src={`https://firebasestorage.googleapis.com/v0/b/appthapi-82dae.appspot.com/o/users%2F${comment.data.userId}%2Favatar?alt=media`}
                  />
                </div>
                <div className="comment-content">
                  <span className="comment-user">{comment.data.userName? comment.data.userName: 'Desconocido'}</span>
                  {comment.data.rating &&(
                    <div className="comment-rating">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <i
                          key={value}
                          className={`fa-star ${value <= comment.data.rating ? 'fas' : 'far'}`}
                          style={{ color: value <= comment.data.rating ? '#ffc107' : '#e4e5e9', marginRight: '5px' }}
                        />
                      ))}
                    </div>
                  )}
                  <span className="comment-text">{comment.data.comment}</span>
                  {showOptions && (
                    <div className="comment-actions">
                      <Whisper
                        trigger="click"
                        placement="bottomEnd"
                        speaker={
                          <Popover>
                            <Button onClick={() => handleDeleteComment(comment.id)}>Eliminar</Button>
                          </Popover>
                        }
                      >
                        <i className="fas fa-ellipsis-v" style={{ cursor: 'pointer', fontSize: '16px' }} />
                      </Whisper>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Modal de oferta solo visible para administradores */}
      {currentUser.admin && (
        <OfferProduct
          show={showOfferModal}
          onHide={handleCloseOfferModal}
          productId={productId}
          product={productData}
        />
      )}
    </div>
  );
}

export default Product;
